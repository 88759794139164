
import CricketFightRoutes from "@/modules/cricket-fight";
import BetsRoutes from "@/modules/bets";
import BonusRoutes from "@/modules/bonus";
import GamesRoutes from "@/modules/games";
import MatkaRoutes from "@/modules/matka";
import ProfileRoutes from "@/modules/profile";
import RaceRoutes from "@/modules/race";
import ReferEarnRoutes from "@/modules/refer-earn";
import SportsRoutes from "@/modules/sports";
import WalletRoutes from "@/modules/wallet";
import MyMarketRoutes from "@/modules/my-market";
import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const Login = () => import("@/modules/authorization/components/Login.vue");
const SignUp = () => import("@/modules/authorization/components/SignUp.vue");
const ForgetPassword = () => import("@/modules/authorization/components/ForgetPassword.vue");


const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/inplay",
        name: "inplay",
        component: Home
    },
    {
        path: "/login",
        name: "login",
        component: Login
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: SignUp
      },
      {
        path: "/forget-password",
        name: "forget-password",
        component: ForgetPassword
      },
    SportsRoutes,
    RaceRoutes,
    GamesRoutes,
    WalletRoutes,
    MyMarketRoutes,
    ProfileRoutes,
    BetsRoutes,
    BonusRoutes,
    ReferEarnRoutes,
    MatkaRoutes,
    CricketFightRoutes  
];

export default createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0 };
    },
});
