<template>
    <section class="nav-new-bar-sec" :class="{ 'home-sec': checkIsLogin(), 'business-type': siteSettings?.business_type == 1 }">
        <div class="nav-middle-menu " ref="navMenu">
            <ul>               
                
                <li>
                    <router-link :to="{ name: 'Home' }" @click="type = 'HOME'" :class="{ 'active': $route.name=='Home'}">
                        <img loading="lazy" src="@/assets/images/menu-home.png" alt="" /> <br />
                        <span>{{translatedLangData('home','Home')}}</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/inplay" @click="type = 'IN_PLAY'" :class="{ 'active': $route.path=='/inplay' }">
                        <img loading="lazy" src="@/assets/images/menu-inplay.png" alt="" /> <br />
                        <span>{{translatedLangData('inplay-footer-label','In-Play')}}</span>
                    </router-link>
                </li>
               

                <li v-for="(sport, n_index) in non_custom_sports" :key="n_index">
                    <router-link :to="'/sports/' + sport.name + '/' + sport.id" @click="type = sport.name"
                        :class="{ 'active':$route.path.includes('/sports/'+sport.name) }">
                        <img loading="lazy" v-if="sport.id == 4" src="@/assets/images/menu-cricket.png" alt="">
                        <img loading="lazy" v-if="sport.id == 1" src="@/assets/images/menu-soccer.png" alt="">
                        <img loading="lazy" v-if="sport.id == 2" src="@/assets/images/menu-tennis.png" alt="">
                        <br />
                        <span> {{ sport?.name == 'soccer' ? 'Football' : sport?.name }} </span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'matka' }" @click="type = 'MATKA'" :class="{ 'active': $route.path.includes('/matka') }">
                        <img class="matka-filter" loading="lazy" src="@/assets/images/icon-matka.svg" alt="" /> <br />
                        <span> {{translatedLangData('matka','Matka')}}</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'cricketFight' }" @click="type = 'cricket-fight'" :class="{ 'active': $route.path.includes('/cricket-fight') }">
                        <img loading="lazy" src="@/assets/images/menu-cricket-fight.webp" alt="" /> <br />
                        <span> {{translatedLangData('cricket-fight','Cricket Fight')}}</span>
                    </router-link>
                </li>
                <li class="hightlight-nav-menu">
                    <router-link :to="{ name: 'casino' }" @click="type = 'CASINO'"
                        :class="{ 'active': $route.path.includes('/casino') }">
                        <img loading="lazy" src="@/assets/images/menu-casino.png" alt="" /> <br />
                        <span>{{translatedLangData('casino','Casino')}}</span>
                    </router-link>
                </li>
                <!-- <li>
                    <router-link :to="{name:'sports',params:{type:'SPORTS_BOOK'}}" @click="type='SPORTS_BOOK'" :class="{'active':type=='SPORTS_BOOK'}">
                        <img loading="lazy" src="@/assets/images/menu-99991.webp" alt="" /> <br />
                        <span> Sports book </span>
                    </router-link>
                </li> -->
                <li class="hightlight-nav-menu">
                    <router-link :to="{ name: 'int-casino' }" @click="type = 'INT_CASINO'" 
                        :class="{ 'active': $route.path.includes('/int-casino') }">
                        <img loading="lazy" src="@/assets/images/casino-title-menu.png" alt="" /> <br />
                        <span> {{translatedLangData('int-casino','Int Casino')}}</span>
                    </router-link>
                </li>
                <li v-for="(c_sport, c_index) in custom_sports" :key="c_index">
                    <router-link :to="'/sports/' + c_sport.name + '/' + c_sport.id" @click="type = c_sport.name"
                        :class="{ 'active': $route.path.includes('/sports/'+c_sport.name) }">
                        <img loading="lazy" v-if="c_sport.slug == 'icc-world1'" src='@/assets/images/menu-icc.png' alt="">
                        <img loading="lazy" v-if="c_sport.slug == 'ipl-winner'" src='@/assets/images/menu-ipl.png' alt=""> 
                        <img loading="lazy" v-if="c_sport.slug == 'lok-sabha-2024\t'" src='@/assets/images/menu-polotics.png' alt=""> <br />
                        <span> {{ c_sport.name }} </span>
                    </router-link>
                </li>
                <!-- <li>
                    <router-link :to="'/sports/binary' + '/' + 123" @click="type='BINARY'" :class="{'active':type=='BINARY'}">
                        <img loading="lazy" src="@/assets/images/menu-99990.webp" alt="" /> <br />
                        <span> Binary </span>
                    </router-link>
                </li> -->

            </ul>
        </div>
        <div class="mobile-toggle-btn-sec">
            <div class="mobile-search-menu">
                <button type="button" data-bs-toggle="offcanvas"  href="#offcanvasExample11" role="button" aria-controls="offcanvasExample11">
                    <span id="menu-toggle1" class="menu-toggle">
                        <img v-if="menuShow" @click="addCloseButton()" src="@/assets/images/Group-menu-icon.webp" alt="" class="group-toggle-icon">
                        <img v-else src="@/assets/images/close-sidebar.webp" alt="" class="close-side-bar-btn" >
                    </span>
                </button>
            </div>
        </div>
        <div class="commentry-sec" v-if="siteSettings?.announcement">
                <div class="commentry-fixed-img">
                    <img loading="lazy" src="@/assets/images/commentary.webp" alt="">
                </div>
                <div class="moving-text">
                    <marquee :style="{ color: siteSettings?.announcement?.color_code }" onmouseover="this.stop();" onmouseout="this.start();">{{ siteSettings?.announcement?.msg }}</marquee>
                </div>
        </div>
    </section>

</template>

<script>
import { mapGetters } from 'vuex';
import click_api from '../../services/click_api';
import * as apiName from '../../services/urls';

export default {
    name: 'NavBar',
    inject: ['translatedLangData'],
    data() {
        return {
            type: 'HOME',
            non_custom_sports: [],
            custom_sports: [],
            // menuShow:true,
        }
    },    
    computed: {
        ...mapGetters({
			menuShow: 'menuShow'
		}),
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
    },
    watch: {
        $route(to, from) {
            this.$nextTick(() => {
                this.scrollToSelected();
            });
        }
    },
    created() {
        this.get_sportsList()
    },
    mounted(){
        this.$store.dispatch('setMenuShow', true)
    },
    methods: { 
        scrollToSelected() {
            this.$nextTick(() => {
                const navMenu = this.$refs.navMenu;
                const selectedElement = navMenu.querySelector('a.active');
                if (selectedElement) {
                    selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        },     
        addCloseButton(){
            const checkBackdrop = document.querySelector('.mobile-offcanvass .offcanvas-backdrop.fade.show');
            checkBackdrop.addEventListener('click', this.handleBackdropClick);                     
            checkBackdrop && (this.$store.dispatch('setMenuShow', false));
        },
        handleBackdropClick(){
            const checkBackdrop = document.querySelector('.mobile-offcanvass .offcanvas-backdrop.fade.show');
            removeEventListener('click', checkBackdrop);
            this.$store.dispatch('setMenuShow', true)
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        get_sportsList() {
            this.$store.dispatch('setGlobalLoader', true);
            click_api.get(apiName.GET_SPORTS).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if (all_sports[i].is_custom == 0) {
                                this.non_custom_sports.push(all_sports[i])
                            } else {
                                this.custom_sports.push(all_sports[i])
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {}
                        sport_list['custom'] = this.custom_sports
                        sport_list['non_custom'] = this.non_custom_sports
                        this.$store.dispatch('setSportsList',sport_list)
                        localStorage.setItem('sports_List', JSON.stringify(sport_list))
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    // this.showErrorModalFunc(error.data.message);
                }
            });
        },
    }
}
</script>