<template>
    <div class="login-model-pop-up-sec">
        <div class="modal fade" id="forget-password-btn" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button @click="refreshData()" ref="close_btn" type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                    <div class="modal-body">
                        <div class="login-body-sec">
                            <div class="login-body-lft">
                                <div class="forget-login">
                                    <div class="login-now">
                                        <div class="login-flow-heading">
                                            <h1 class="login-form">{{ translatedLangData('forgot-password','Forgot Password')}}?</h1>
                                        </div>
                                        <!-- <div class="whatsapp-btn">
                                                <button type="button" class="btn thm-but" id="regWhatsApp">
                                                    <img loading="lazy" src="assets/images/whatsApp-icon.webp" alt=""> 
                                                    <span class="blinking">Whatsapp Now</span>
                                                </button>
                                                <p class="or-text">OR create account with Mobile Number</p>
                                            </div>
                                            <div id="msgFromServer"></div> -->

                                        <div method="post" id="register_form">
                                            <input type="hidden" name="_token" id="csrf-token">
                                            <div class="number-var mak-gin for-pass-wrd">
                                                <div class="row g-1">
                                                    <div class="col-12 col-sm-12 col-md-12">
                                                        <div class="row g-0">
                                                        <div class="col-12 col-sm-12 col-md-12">
                                                            <div class="contact-info-wrapper">
                                                                <div class="select-country-code">
                                                                    <select v-model="form.code" class="form-select form-control codein valid" id="autoSizingSelect" :disabled="isOtpApiCalled">
                                                                        <option :value="allowedCountry.phonecode"
                                                                        v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                        :key="countryIndex" :disabled="allowedCountry.phonecode!='91'">+{{ allowedCountry.phonecode }}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="input-left">
                                                                    <input type="tel" maxlength="10" class="form-control" :class="isMobileNoValid ? '': 'border border-2 border-danger'" @input="numberFormat('mobileNo')" @keypress="checkForNonNumericals($event)" @keyup="showWarningInFields('MobileNo'),showWarningsAbove()" @paste="showWarningInFields('MobileNo'),showWarningsAbove()"
                                                                        id="mobile" v-model="form.mobileNo"
                                                                        :placeholder="translatedLangData('mobile-number','Mobile Number')+'*'" :disabled="isOtpApiCalled">
                                                                </div>
                                                            </div>
                                                            <div v-if="showValidationError && form.mobileNo == ''" class="">
                                                                <p class="wrong-message"> {{ translatedLangData('please-enter-mobile-number','Please Enter Mobile Number.') }}</p>
                                                            </div>
                                                            <div v-if="showValidationError && form.mobileNo?.length < 10 && form.mobileNo !=''" class="">
                                                                <p class="wrong-message">&#8226; {{ translatedLangData('mobile-number-should-be-of-10-digits','Mobile Number Should Be Of 10 Digits.') }}</p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                        <div class="col-12 mak-gin mo-number-right">
                                                            <span v-if="timerRunning"
                                                                style="margin-right: 10px;color: white;">{{
                                                                    formatTime(minutes) + ':' +
                                                                    formatTime(seconds) }}</span>
                                                            <button @click="sendOtpCall()" type="button"
                                                            :disabled="loadingOtp || timerRunning || !isMobileNoValid || this.form.mobileNo==''" id="otp-btn"
                                                                class="thm-btn me-2 thm-boder-btn otp-btn">
                                                                <span v-if="!loadingOtp">{{ resendOtp ? translatedLangData('resend-otp','Resend OTP') : translatedLangData('get-otp',"Get OTP") }}</span>
                                                                <div v-if="loadingOtp" class="text-center" id="Otp_loader">
                                                                    <div class="spinner-border" role="status">
                                                                        <span class="visually-hidden">{{translatedLangData('loading','Loading')}}...</span>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mak-gin password-inpt mt-2" v-if="isOtpApiCalled">
                                                <input type="tel" class="form-control input-boxes" name="otp" maxlength="6" :class="this.isOtpApiCalled && this.form.otp=='' ? 'border border-2 border-danger': ''" :placeholder="translatedLangData('enter-otp','Enter OTP')+'*'"
                                                @input="numberFormat('otp')" @keypress="checkForNonNumericals($event)" @keyup="showWarningInFields('Otp'),showWarningsAbove()" id="otp-input" v-model="form.otp" required="">
                                                <div v-if="showValidationError && form.otp==''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('please-enter-otp','Please Enter OTP') }}</p>
                                                </div>
                                            </div>

                                            <div class="mak-gin password-inpt">
                                                <input :type="form.passwordVisible?'text':'password'" class="form-control toggle-password" name="password" maxlength="20"
                                                    id="password" :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" :disabled="isDisabled" @input="filterSpaces($event,'password'),handleInputUserID($event,'password')" :class="isPasswordFieldValid ? '': 'border border-2 border-danger'" @keyup="showWarningInFields('PasswordField'),showWarningsAbove()" @paste="showWarningInFields('PasswordField'),showWarningsAbove()"
                                                    aria-describedby="password">

                                                    <div class="score-hide-show">
                                                        <img loading="lazy" @click="form.passwordVisible=!form.passwordVisible" class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                         v-if="form.passwordVisible" alt="img">
                                                        <img loading="lazy" v-else @click="form.passwordVisible=!form.passwordVisible" class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"  alt="img">
                                                    </div>

                                                <div v-if="showValidationError && isOtpApiCalled && form.otp && form.password == ''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('please-enter-password','Please Enter Password') }}</p>
                                                </div>
                                                <div v-if="showValidationError && form.password.length<8 && form.password!=''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('pass-min-8-max-20','Password Must Be Of Minimum 8 Characters And Maximum 20 Characters.') }}</p>
                                                </div>
                                                <div v-if="showValidationError && !/^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/.test(form.password) && form.password!=''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('pass-alpha-num-1-caps','Password Must Contains Alphabets, Numbers And Atleast 1 In Capital Case.') }}</p>
                                                </div>
                                            </div>
                                            <div class="mak-gin password-inpt">
                                                <input :type="form.confirmPassVisible?'text':'password'" class="form-control toggle-password" maxlength="20"
                                                    id="confirm_password" v-model="form.confirmPassword" :disabled="isDisabled" @input="filterSpaces($event,'confirmPassword'),handleInputUserID($event,'confirmPassword')" :class="isConfirmPasswordFieldValid || this.form.password === this.form.confirmPassword ? '': 'border border-2 border-danger'" @keyup="showWarningInFields('ConfirmPasswordField'),showWarningsAbove()" @paste="showWarningInFields('ConfirmPasswordField'),showWarningsAbove()"
                                                    name="confirm_password" :placeholder="translatedLangData('confirm-password','Confirm Password')+'*'"
                                                    aria-describedby="password">
                                                    <div class="score-hide-show">
                                                        <img loading="lazy" @click="form.confirmPassVisible=!form.confirmPassVisible" class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                        v-if="form.confirmPassVisible" alt="img">
                                                        <img loading="lazy" v-else @click="form.confirmPassVisible=!form.confirmPassVisible" class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"  alt="img">
                                                    </div>

                                                <div v-if="showValidationError && form.password && form.confirmPassword==''"
                                                    class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('please-enter-confirm-password','Please Enter Confirm Password') }}</p>
                                                </div>
                                                <div v-if="showValidationError && form.password !== form.confirmPassword && form.confirmPassword!=''"
                                                    class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('password-conf-pas-not-matched','Password And Confirm Password Not Matched.') }}</p>
                                                </div>
                                            </div>

                                           

                                            <input type="hidden" id="isDownLine" name="isDownLine" value="">

                                            <button :disabled="!isFormValid" @click="forgetPasswordCall()" type="submit"
                                                class="btn thm-but" id="submitBtn">
                                                <span v-if="!loadingUpdate" :class="!isFormValid ? 'disabled':''">{{ translatedLangData('reset','Reset') }}</span>
                                                <div v-if="loadingUpdate" class="text-center" id="Otp_loader">
                                                    <div class="spinner-border" role="status">
                                                        <span class="visually-hidden">{{ translatedLangData('loading','Loading') }}...</span>
                                                    </div>
                                                </div>
                                            </button>


                                            <p class="forpass-in">{{ translatedLangData('remember-your-password','Remember your password')}}?<a href="#login-btn"
                                                    data-bs-toggle="modal" @click="refreshData">{{ translatedLangData('log-in','Login')}}</a></p>
                                            <div class="create-new-acc">
                                                <a href="#register-btn" data-bs-toggle="modal" @click="refreshData">{{ translatedLangData('create-new-account','Create New Account')}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="login-body-rgt">
                                <div class="banner-side-img-sec">
                                    <div class="slider-model-pic">
                                        <div class="slider-model-pic">
                                            <img loading="lazy" src="@/assets/images/sl-lc-one-layer-two.webp" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import BannerCarousel from '@/shared/components/banner/BannerCarousel.vue';
import { Modal } from "bootstrap"

export default {
    name: "ForgetPassword",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                otp: '',
                password: "",
                passwordVisible:false,
                confirmPassword: "",
                confirmPassVisible:false,
            },
            loadingOtp: false,
            loadingUpdate: false,

            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            untouchedMobileNo: true,
            untouchedOtp: true,
            untouchedPasswordField: true,
            untouchedConfirmPasswordField: true,
            isDisabled:true,
        };
    },
    computed: {
            siteSettings() {
                return this.$store.getters.siteSettings
            },
            isFormValid() {
                return this.getOtp() && this.form.otp!=='' &&  this.isPasswordFieldValid && this.form.confirmPassword == this.form.password && this.form.password;
            },
            isMobileNoValid() {
                return this.untouchedMobileNo || this.form.mobileNo.length === 10;
            },
            isPasswordFieldValid() {
                return this.untouchedPasswordField || (
                    this.form.password.length >= 8 &&
                    this.form.password.length <= 20 &&
                    /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/.test(this.form.password)
                );
            },
            isConfirmPasswordFieldValid() {
                return this.untouchedConfirmPasswordField || (
                    this.form.confirmPassword.length >= 8 &&
                    this.form.confirmPassword.length <= 20 &&
                    /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/.test(this.form.confirmPassword)
                );
            }
    },
    mounted(){
        if(this.$route.name == 'forget-password' && !this.checkIsLogin){
            setTimeout(()=> {
                let loginModal = new Modal('#forget-password-btn');
                loginModal.show();
            }, 500)
            this.$router.push({name: 'Home'});
        }
        else if(this.$route.name == 'forget-password' && this.checkIsLogin){
            this.$router.push('/')
        }
    },
    methods: {
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showWarningsAbove(){
            this.showValidationError = false;
            if (!this.isMobileNoValid) {
                this.showValidationError = true;
            }
            if (this.isOtpApiCalled && this.form.otp=='') {
                this.showValidationError = true;
            }
            if (this.isOtpApiCalled && this.form.otp && this.form.password=='') {
                this.showValidationError = true;            
            } 
            if(this.form.password.length<8 && this.form.password!=''){
                this.showValidationError = true;
            }
            if(!/^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/.test(this.form.password) && this.form.password!=''){
                this.showValidationError = true;
            }
            if (this.form.password && this.form.confirmPassword=='') {
                this.showValidationError = true;            
            }            
            if(this.form.password !== this.form.confirmPassword && this.form.confirmPassword!=''){
                this.showValidationError = true;
            }            
        }, 
        showWarningInFields(prop) {
            this[`untouched${prop}`] = false
        },
        numberFormat(prop) {
            // Convert the input to a string
            if (this.form[prop] != '') {
                let stringValue=''
                if(typeof this.form[prop]!='string'){
                    stringValue = this.form[prop].toString();
                }
                else{
                    stringValue = this.form[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                // Convert the sanitized string back to a number
                if(typeof this.form[prop]!='string'){
                    this.form[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.form[prop] = stringValue
                }                
            }
        },
        checkForNonNumericals(event) {
            // Prevent input of the letter 'e'
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
            if (event.key === '-' || event.key === '+' || event.key === '.') {
                event.preventDefault();
            }
        },
        handleInputUserID(event,prop) {
            prop=='otp' ? this.form[prop] = event.target.value.replace(/[^0-9]/g, '').slice(0, 25) : this.form[prop] = event.target.value.replace(/[^\w]/g, '').replace(/_/g, '').slice(0, 25);
            const containsSpaces = /\s/.test(this.form[prop]);
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
            event.target.value = this.form[prop].slice(0, 25);
        },
        filterSpaces(event,prop) {
            const sanitized = event.target.value.replace(/[^\w !@#$%^&*()+\-*/]/g, '');
            this.form[prop] = sanitized;
            const containsSpaces = /\s/.test(this.form[prop]);            
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: '',
                password: "",
                confirmPassword: "",
                passwordVisible:false,
                confirmPassVisible:false,
            };
            this.showValidationError = false;
            this.showErrorMobile = false;
            this.passwordValidation = false;
            this.isOtpApiCalled = false;
            this.resendOtp = false;
            this.resendOtpTimer = 0;
            this.timerRunning = false;
            this.minutes = 0;
            this.seconds = 0;
            this.untouchedMobileNo= true;
            this.untouchedOtp= true;
            this.untouchedPasswordField= true;
            this.untouchedConfirmPasswordField= true;
            this.isDisabled=true;
        },
        sendOtpCall() {
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else {
                this.sendOtpServiceCall();
            }
        },
        sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.showErrorMobile = false;
                        this.isDisabled = false;
                        this.showSuccessModalFunc(response.data.message);
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }

                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        forgetPasswordCall() {
            this.showValidationError = false;
            if (this.form.mobileNo == "" ) {
                this.showValidationError = true;
            }
            else if (this.form.otp.length != 6 || this.form.password == '' || this.form.confirmPassword == '') {
                this.showValidationError = true;
            }
            else if (this.form.password != this.form.confirmPassword) {
                this.showValidationError = true;
            }
            else {
                this.forgotServiceCall();
            }
        },
        forgotServiceCall() {
            this.loadingUpdate = true;
            this.$store.dispatch('setGlobalLoader', true);            
            let data = {
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.getOtp(),
                "cncode": this.form.code,
            };
            api.post(apiName.FORGOT_PASSWORD, data).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                this.loadingUpdate = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                this.loadingUpdate = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        // getOtp() {
        //     return this.form.otp.toString().replaceAll(',', '');
        // },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },

    },
    components: { BannerCarousel }
};
</script>