<template>
  <MobileSideMenu />
  <header class="" :class="!checkForHeaderShow() ? 'header-0px' : 'header-wapper'" loading="lazy">
    <Header @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" v-if="checkForHeaderShow()"/>
  </header>

  <section :class='sectionClass' class="main-body-container-sec">
    <div class="row g-0">
      <div class="col-0 col-sm-0 col-md-0 col-lg-2">
        <SideMenu />
      </div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-10">
        <div>
          <router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
        </div>
      </div>
    </div>
  </section>  
  <Footer v-if="$route.name=='Home' || $route.name=='inplay'"/>
  <template v-if="!checkIsLogin()">
    <Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
    <SignUp @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
    <ForgetPassword @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
  </template>
  <div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe">
	</div>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>


  <div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
    ref="successmodal" id="successfullyModal">
    <SuccessModal :message="successMsg" />
  </div>

  <div :class="{ show: showErrorModal }" style="z-index: 9999999999999999;" class="successfully-wrapper"
    id="wrongtoaster">
    <ErrorModal :message="errorMsg" />
  </div>

  <GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
  <GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

  <LanguageSelection @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
  <Offers />

  <BonusInfoModal />
  <DownloadApkModal/>

  <div id="load" v-if="getGlobaLoader()">
        <div id="load-inner"><div class="spinner-rotate"></div></div>
  </div>
</template>

<script>
import LanguageService from '@/shared/services/language-service.js'
import NotificationAlert from "./shared/components/modal/NotificationAlert.vue";
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import { setHeaders } from '@/shared/services/headers';
import axios from 'axios';

import Header from '@/shared/components/header/Header.vue';
import MobileSideMenu from '@/shared/components/side-menu/MobileSideMenu.vue';
import SideMenu from '@/shared/components/side-menu/SideMenu.vue';
import Footer from '@/shared/components/footer/Footer.vue';
import Login from '@/modules/authorization/components/Login.vue';
import SignUp from "./modules/authorization/components/SignUp.vue";
import ForgetPassword from "./modules/authorization/components/ForgetPassword.vue";

import SuccessModal from '@/shared/components/modal/SuccessModal.vue';
import ErrorModal from '@/shared/components/modal/ErrorModal.vue';

import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import GamesPop1 from "./shared/components/modal/GamesPop1.vue";
import GamesPop2 from "./shared/components/modal/GamesPop2.vue";
import LanguageSelection from "./shared/components/header/LanguageSelection.vue";
import BonusInfoModal from "./shared/components/header/BonusInfoModal.vue";
import socketService from "./socket/sport-socket-service";
import { provide, computed} from 'vue';

import { callFavouriteApis } from '@/shared/services/common-services';
import { mapGetters } from 'vuex';
import Offers from "./shared/components/header/Offers.vue";
import { Modal } from "bootstrap";
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';
import moment from 'moment';

export default {
  name: 'App',
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
      loading: false,

      showSuccessModal: false,
      successMsg: "",
      showErrorModal: false,
      errorMsg: "",
      scY: 0,
			scTimer: 0,
			screenSize: window.innerWidth,
			mobileView: 1023,
			findActiveLang:"",
			allLanguages:null,
			langData: null,
      offerModal: '',
    }
  },
  components: {
    Header,
    MobileSideMenu,
    SideMenu,
    Footer,
    Login,
    SignUp,
    ForgetPassword,
    SuccessModal,
    ErrorModal,
    GamesPop1,
    GamesPop2,
    LanguageSelection,
		NotificationAlert,
    BonusInfoModal,
    Offers,
    DownloadApkModal
  },
  provide() {
		return {
			translatedLangData: computed(() => this.translatedData)
		};
	},
  computed:{
    ...mapGetters({
			eventDetailHideSections: 'eventDetailHideSections',
      siteSettings:'siteSettings'
		}),
    routePath() {
			return this.$route.name
		},
    sectionClass() {
      if (!this.checkForHeaderShow()) {
        return 'padding-top-0';
      } else if (!this.checkIsLogin()) {
        if (!this.siteSettings?.announcement) {
          return 'padding-top-101';
        } else {
          return 'padding-top-105';
        }
      } else {
        if (!this.siteSettings?.announcement) {
          return this.siteSettings?.business_type==2 ? 'padding-top-150' : 'padding-top-101';
        } else {
          return this.siteSettings?.business_type==2 ? 'padding-top-170' : 'padding-top-105';
        }
      }
    },
  },
  created(){
    this.getSiteSettingsServiceCall();

  },
  mounted() {
    //for use on js pages
    window.store = this.$store;
    window.cookies = this.$cookies;
    window.router = this.$router;
    this.offerModal = new Modal("#offer_pop_up");window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
    if((window.location.href).includes('refer_code')){
      let signupModal = new Modal("#register-btn");
      signupModal.show();
    }
    //for add on all APIs
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
    setHeaders();
    if(this.checkIsLogin()) {
    	callFavouriteApis();
      if(this.$store.getters.stateUser.notificationUnsubscribed==1){
				return;
			}
			if(this.$store.getters.stateUser.notificationSubscribed!=1) {
				this.requestNotificationPermission()
			}
    }
    
    if (!this.checkIsLogin()) {
    }
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.socketAllEvents();
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    checkForHeaderShow() {
			 if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
    getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
    translatedData(key, defaultVal) {
			if(this.langData?.[key]) {
				return this.langData?.[key];
			} else {
				return defaultVal;
			}
		},
    requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				api.post(apiName.SUBSCRIBE_NOTIFICATION, data, { headers }).then(response => {
					this.loadingSignUp = false;
					if (response) {
					}
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.showErrorModalFunc(error[0]);
				});
		},
    async getSiteSettingsServiceCall() {
      this.$store.dispatch('setGlobalLoader', true);
      api.get(apiName.SITE_SETTINGS + "?domain=" + window.location.hostname).then(async response => {
        this.$store.dispatch('setGlobalLoader', false);
        if (response) {
          if (response.status == 200) {
            if (response?.data?.status == 0) {
              this.showErrorModalFunc(response.data.debug[0]);
            }
            else {
              //this.showSuccessModalFunc(response.data.message);
              let resData = response?.data?.data
              this.allLanguages = resData.language_translate_list;
              this.$store.dispatch('setLanguagesData', this.allLanguages);
              if (this.allLanguages) {
                this.findActiveLang =this.allLanguages.find(lang => lang.lang_code === resData.language_code);
                const selectedLanguageUrl =  localStorage.getItem('selectedLanguageUrl');
                      if (!(selectedLanguageUrl === 'en' || (!selectedLanguageUrl && resData.language_code === 'en'))) {
                if ((this.findActiveLang && this.findActiveLang.lang_url) || selectedLanguageUrl ) {
                  const langCode = selectedLanguageUrl || this.findActiveLang.lang_url;
                  const aws_url_language = resData.aws_url+ '/' +langCode
                  this.langData = await LanguageService.getLanguageTranslationKey(aws_url_language)
                  }
                }
              }
              this.$store.dispatch('setSettings', resData);
              this.setManifest(resData);

              // Create FAVICON
              const favElement = document.createElement('link');
              favElement.rel = 'icon';
              favElement.href = resData.aws_url + resData.storage_path.domain_image + resData.fav_logo;
              document.head.insertAdjacentElement('beforeend', favElement);

              const title = document.getElementById("title");
              title.text = resData?.domain_name;
              let is_offer = localStorage.getItem("is_offer");
              try {
                if (resData.front_css != null && resData.front_css != '') {
                  const styleElement = document.createElement('style');
                  styleElement.type = 'text/css';
                  styleElement.appendChild(document.createTextNode(resData.front_css));
                  document.head.insertAdjacentElement('beforeend', styleElement);
                }
              } catch (error) {
              }
              if (this.checkIsLogin())
                this.getWalletBalance();
              if (this.checkIsLogin() && resData.offer != null && is_offer == null) {
                this.offerModal.show();
              }
            }
          }
        }
      }).catch(error => {
        this.$store.dispatch('setGlobalLoader', false);
        if (error) {
          this.showErrorModalFunc(error[0]);
        }
      });
    },
    setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
    checkIsLogin() {
      return this.$store.getters.isAuthenticated;
    },

    getWalletBalance() {
      let authToken = this.$cookies.get(AUTH_TOKEN);
      if (!authToken) return;
      let headers = {
        'Authorization': `Bearer ${authToken}`
      }
      api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
        if (response && response.status == 200 && response.data) {
          let user = this.$store.getters.stateUser;
          user.balance = response.data.bl;
          user.exposure = response.data.ex;
          user.bets_count = response.data.bc;
          this.$store.dispatch('setUser', user);
          if ((user.exp - moment().utc().unix()) < 10) {
            this.refreshAuthToken(headers);
          } else {
            setTimeout(function () {
              this.getWalletBalance();
            }.bind(this), 2000);
          }
        } else {
          this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
          this.$store.dispatch('resetAllState');
        }
      }).catch(error => {
        if (error) {
          this.showErrorModalFunc(error[0]);
        }
      });
    },

    refreshAuthToken(headers) {
      this.refreshInit = true;
      api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
        let user = this.$store.getters.stateUser;
        user.token = response.data.data.token;
        user.exp = response.data.data.exp;
        this.$cookies.set(AUTH_TOKEN, response.data.data.token);
        this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
        //for add on all APIs
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
        this.$store.dispatch('setUser', user);
        this.refreshInit = false;
        setTimeout(function () {
          this.getWalletBalance();
        }.bind(this), 2000);
      }).catch(error => {
        if (error) {
          this.showErrorModalFunc(error[0]);
        }
        this.refreshInit = false;
      });
    },


    updateOnlineStatus(e) {
      const {
        type
      } = e;
      this.onLine = type === 'online';
    },
    showErrorModalFunc(message) {
      if (message != null && message != '') {
        this.errorMsg = message;
        this.showErrorModal = true;
        setTimeout(() => {
          this.showErrorModal = false;
        }, 2000);
        //window.scrollTo(0, 0);
      }
    },
    showSuccessModalFunc(message) {
      if (message != null && message != '') {
        this.successMsg = message;
        this.showSuccessModal = true;
        setTimeout(() => {
          this.showSuccessModal = false;
        }, 2000);
        //window.scrollTo(0, 0);
      }
    },
    socketAllEvents() {
      if (this.$store.getters.stateUser) {
        socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
        socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
        socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

        socketService.on("DepositRequest", (data) => {
          if (data.type == "myId") {
            if (data.notify_type == 1) {
              this.$store.dispatch('setNotification');
              this.showSuccessModalFunc('New Notification...')
            }
          }
        });
        socketService.on("logout", (data) => {
          if (data.type == "logout") {
            // logout user from users
            // if (data.notify_type == 1) {
            // 	this.$store.dispatch('setNotification');
            // 	this.showSuccessModalFunc('New Notification...')
            // }
          }
        });
        socketService.on("banned", (data) => {
          if (data.type == "match_ban") {
            // match ban condition
          } else if (data.type == 'league_ban') {
            // league ban condition
          }
        });

      }
    }
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    }
  }
}
</script>
