<template>
    <div class="container">
        <div class="navbar-sec">
            <div class="nav-left">
                <ul>
                    
                    <li v-if="siteSettings">
                        <router-link to="/" class="desktop-logo">
                            <img loading="lazy" :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo"
                                alt="" />
                        </router-link>
                        <router-link to="/" class="mobile-logo">
                            <img loading="lazy" :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo"
                                alt="" />
                        </router-link>
                    </li>
                    <li v-if="checkIsLogin()" class="input-form">
                        <div action="">
                            <input :value="getSearchText()" type="text" @input="onChange($event)" class="form-control"
                                placeholder="Search Events" />
                            <span class="search-icon"><i class="fa-solid fa-magnifying-glass"></i></span>
                        </div>
                    </li>

                    <li class="rules-box">
                        <div class="d-w-box">
                            <ul>
                                <li v-if="checkIsLogin()">
                                    <a href="#rules" data-bs-toggle="modal">{{translatedLangData('rules', 'Rules')}}</a>
                                </li>
                                <li v-if="checkIsLogin()" class="cnm-deposit-wdr-btn">
                                    <router-link to="/wallet" class="deposit-btn">
                                        <img loading="lazy" src="@/assets/images/deposit-icon.webp" alt="">
                                        <span>{{translatedLangData('wallet', 'Wallet')}}</span>
                                    </router-link>
                                </li>
                                <!-- <li v-if="checkIsLogin()" class="cnm-deposit-wdr-btn">
                                    <router-link to="/bonus" class="withdrowal-btn">
                                        <img loading="lazy" src="@/assets/images/withdrawal-icon.webp" alt="">
                                        <span>{{translatedLangData('bonus', 'Bonus')}}</span>
                                    </router-link>
                                </li> -->
                            </ul>
                        </div>
                    </li>

                </ul>
            </div>
            <div class="nav-right header-nav-rgt">
                <ul v-if="!checkIsLogin()" class="login-sign-btn-m">
                    <li v-if="siteSettings && siteSettings?.business_type == 2">
                        <a href="#register-btn" data-bs-toggle="modal" class="cmn-btn12">{{translatedLangData('register', 'Signup')}}</a>
                    </li>
                    <li>
                        <a href="#login-btn" data-bs-toggle="modal" class="cmn-btn12 login-btn-header">{{translatedLangData('log-in', 'Login')}}</a>
                    </li>
                </ul>

                <ul v-if="checkIsLogin() && userData()" class="exposer-user-h">
                    <li>
                        <a class="bal-exp">
                            <span> {{translatedLangData('bal','BAL')}}  </span>
                            <b>{{ userData().balance }}</b>
                        </a>
                    </li>
                    <li>
                        <router-link to="/market-analysis" class="bal-exp exp-bal-show">
                            <span> {{translatedLangData('exp','EXP')}} </span>
                            <b>{{ userData().exposure!=null ? -parseInt(userData().exposure) : 0 }}</b>
                        </router-link>
                    </li>
                    <li>
                        <div class="dropdown open-menu-btn">
                            <button v-if="userData()" class="btn btn-secondary dropdown-toggle" type="button"
                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <!-- <span><i class="fa-solid fa-user"></i></span> -->
                                <span class="text-capitalize">{{ userData().userid }}</span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li class="mobile-num"><span class="text-capitalize">Hi, {{ userData().userid }}</span></li>
                                <div class="right-side-menu-scrollbar">                              
                                    <div class="setting-user-checkbox" onclick="event.stopPropagation();">
                                        <div class="user-details-r">
                                            <label class="form-check-label" for="flexSwitchCheckDefault" >{{translatedLangData('one-click-bet', 'One Click Bet')}}</label>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" v-model="clickBetCheck"
                                                    @change="betCheckChangeFunc()">
                                            </div>
                                            <div v-if="clickBetCheck && getClickBetSelectedValue()"
                                                @click="$refs.open_click_bet_value.click()">
                                                <label>{{ getClickBetSelectedValue() }}</label>
                                            </div>
                                        </div>
                                        <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="modal"
                                            data-bs-target="#clickBetValues"></a>
                                    </div>
                                    <li class="menu-rgt-icons">
                                        <router-link :to="{ name: 'Home' }" class="dropdown-item">
                                            <img loading="lazy" src="@/assets/images/menu-home.png" alt="">
                                            <span class="menu-rgt-text">{{translatedLangData('home','Home')}}</span>
                                        </router-link>
                                    </li>
                                    <li class="menu-rgt-icons depo-bg" v-if="siteSettings && siteSettings?.business_type == 2">
                                        <router-link :to="{ name: 'wallet-gateway' }" class="dropdown-item">
                                            <img loading="lazy" src="@/assets/images/deposit-icon.webp" alt="">
                                            <span class="menu-rgt-text depo-head">{{translatedLangData('wallet','Wallet')}}</span>
                                        </router-link>
                                    </li>                                    
                                    <li class="bonus-information-bx" v-if="siteSettings && siteSettings?.business_type == 2">
                                        <div class="credits-chackn-box" @click="getRules()" data-bs-toggle="modal" data-bs-target="#informationModal">
                                            <div class="menu-details-heading" >
                                                <h4>{{translatedLangData('bonus-information', 'Bonus Information')}}</h4> 
                                                <span class="information-icon"  >
                                                        <a href="#"><img src="@/assets/images/info-icon.webp" alt=""></a>
                                                </span>
                                                
                                            </div>
                                        </div>
                                        
                                        <div class="bonus-sec" v-if="lockedEarnedData">
                                            <div class="credits-chackn-box"  onclick="event.stopPropagation();">
                                                <div class="menu-details-heading">
                                                    <h4>{{translatedLangData('earn-bonus', 'Earn Bonus')}}</h4> 
                                                    <span class="ml-2"> {{ lockedEarnedData.earnBonus }}</span>
                                                </div>
                                            </div>
                                            <div class="credits-chackn-box">
                                                <div class="menu-details-heading credits-bonus-list" @click="openBonusLogs()">
                                                    <div class="locked-bonus-dflex">
                                                        <h4><button class="btn locked-bonus-btn">{{translatedLangData('locked-bonus', 'Locked Bonus')}}</button> 
                                                        </h4> 
                                                        
                                                    </div>
                                                    <span class="ml-2"> {{ lockedEarnedData.lockedBonus }}</span>
                                                </div>
                                            </div>
                                        

                                        
                                            <div class="credits-chackn-box" onclick="event.stopPropagation();">
                                                <div class="menu-details-heading">
                                                    <h4 >{{translatedLangData('welcome-bonus', 'Welcome Bonus')}} </h4>
                                                    <span>{{ lockedEarnedData.welcomeBonus }}</span>
                                                </div>
                                            </div>
                                            <div class="credits-chackn-box border-0" onclick="event.stopPropagation();">
                                                <div class="menu-details-heading">
                                                    <h4>{{translatedLangData('play-with-bonus', 'Play with Bonus')}}</h4>
                                                </div>
                                                <div class="menu-heading-con">
                                                    <div class="form-check form-switch m-0 p-0">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="settingCheckDefaults" v-model="getWalletType" @change="updateWalletType">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="menu-rgt-icons">
                                        <a data-bs-toggle="modal" data-bs-target="#language_selection_pop_up">
                                            <!-- <img loading="lazy" src="@/assets/images/language-list-icon.webp" alt=""> -->
                                            <i class="bi bi-globe"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('language','Language')}}</span>
                                        </a>
                                    </li>

                                    <li class="menu-rgt-icons" v-if="siteSettings?.business_type == 2">
                                        <router-link :to="{ name: 'refer-earn' }" class="dropdown-item">
                                            <!-- <img loading="lazy" src="@/assets/images/menu-home.webp" alt=""> -->
                                            <i class="bi bi-people"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('refer-and-earn','Refer Earn')}}</span>
                                        </router-link>
                                    </li>

                                    <li class="menu-rgt-icons">
                                        <router-link :to="{ name: 'profile', params: { type: 'PROFILE_OVERVIEW' } }"
                                            class="dropdown-item">
                                            <!-- <img loading="lazy" src="@/assets/images/profile.webp" alt=""> -->
                                            <i class="bi bi-person"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('my-profile','My Profile')}}</span>
                                        </router-link>
                                    </li>
                                    <li class="menu-rgt-icons">
                                        <router-link :to="{ name: 'statements' }" class="dropdown-item" href="statement.html">
                                            <!-- <img loading="lazy" src="@/assets/images/file.webp" alt=""> -->
                                            <i class="bi bi-bar-chart-steps"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('account-statement','Account statement')}}</span>
                                        </router-link>
                                    </li>
                                    <li class="menu-rgt-icons">
                                        <router-link :to="{ name: 'profile', params: { type: 'STAKE_SETTING' } }"
                                            class="dropdown-item" href="stake.html">
                                            <!-- <img loading="lazy" src="@/assets/images/bullseye.webp" alt=""> -->
                                            <i class="bi bi-bullseye"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('stake-settings','Stake Settings')}}</span>
                                        </router-link>
                                    </li>
                                    <li class="menu-rgt-icons">
                                        <router-link :to="{ name: 'profit-loss' }" class="dropdown-item"
                                            href="profit-loss.html">
                                            <!-- <img loading="lazy" src="@/assets/images/line-chart.webp" alt=""> -->
                                            <i class="bi bi-bar-chart-line"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('profit-loss','Profit & Loss')}}</span>
                                        </router-link>
                                    </li>
                                    <li class="menu-rgt-icons">
                                        <router-link :to="{ name: 'unsettled-bets' }" class="dropdown-item">
                                            <!-- <img loading="lazy" src="@/assets/images/line-chart.webp" alt=""> -->
                                            <i class="bi bi-bar-chart"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('unsettled-bets','Unsettled Bets')}}</span>
                                        </router-link>
                                    </li>
                                    <li class="menu-rgt-icons">
                                        <router-link :to="{ name: 'BetHistory' }" class="dropdown-item">
                                            <!-- <img loading="lazy" src="@/assets/images/line-chart.webp" alt=""> -->
                                            <i class="bi bi-file-text"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('bet-history','Bet History')}}</span>
                                        </router-link>
                                    </li>
                                    <li class="menu-rgt-icons">
                                        <router-link :to="{ name: 'profile', params: { type: 'CHANGE_PASSWORD' } }"
                                            class="dropdown-item" href="change-password.html">
                                            <!-- <img loading="lazy" src="@/assets/images/padlock.webp" alt=""> -->
                                            <i class="bi bi-lock"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('change-password','Change Password')}}</span>
                                        </router-link>
                                    </li>
                                    
                                </div>
                                <li @click="logoutBtnClick()" class="menu-rgt-icons">
                                        <a class="dropdown-item signout-btn">
                                            <!-- <img loading="lazy" src="@/assets/images/logout.webp" alt=""> -->
                                            <i class="bi bi-box-arrow-right"></i>
                                            <span class="menu-rgt-text">{{translatedLangData('sign-out','Sign Out')}}</span>
                                        </a>
                                    </li>
                            </ul>
                        </div>
                    </li>
                </ul>

            </div>
        </div>

    </div>

    <div v-if="checkIsLogin() && siteSettings && siteSettings?.business_type == 2" class="deposit-and-wdrl--m-sec">
        <div class="deposit-m">
            <router-link :to="{ name: 'wallet-gateway' }">
                <img loading="lazy" src="@/assets/images/deposit-icon.webp" alt="">
                <span>{{translatedLangData('deposit','Deposit')}}</span>
            </router-link>
        </div>
        <div class="withdraw-m">
            <router-link :to="{ name: 'wallet-gateway' }">
                <img loading="lazy" src="@/assets/images/withdrawal-icon.webp" alt="">
                <span>{{translatedLangData('withdrawal','Withdrawal')}}</span>
            </router-link>
        </div>
    </div>

    <NavBar />
    <BonusInformation :siteRuleData="siteRuleData"/>
    <ClickBetModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" @cancelOneClick = "clickBetCheck = false" />
    <LockedBonus v-if="showBonusLogs" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" @closeBonusLogs="closeBonusLogs()" />

</template>
<script>

import BonusInformation from '@/shared/components/header/BonusInfoModal.vue';
import { mapGetters } from 'vuex';
import ClickBetModal from '@/shared/components/modal/ClickBetModal.vue';
import NavBar from './NavBar.vue';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import LockedBonus from '@/shared/components/header/LockedBonus.vue';
import { Modal } from 'bootstrap';

export default {
    name: 'Header',
    inject: ['translatedLangData'],
    data(){
        return{
            clickBetCheck: this.$store?.getters?.isClickBetOn ? this.$store?.getters?.isClickBetOn : false,
            getWalletType:false,
            lockedEarnedData: null,
            showBonusLogs: false,
            siteRuleData: null
        }
    },
    mounted() {
        if(this.checkIsLogin() && this.siteSettings?.business_type == 2) {
            this.getLockedEarnedBonus();
            this.getWalletType = this.stateUser?.activeWallet == 'WB';
        } 
    },
    components: {
        NavBar,
        LockedBonus,
        ClickBetModal,
        BonusInformation
    },
    computed: {
        ...mapGetters([
            'siteSettings', 'stateUser', 'getBalExpDisplay', 'getRecommendedMatch'
        ]),
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    methods: {
        updateWalletType() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.post(apiName.UPDATE_WALLET_TYPE, {} , {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let user = this.stateUser;
                            user.activeWallet = response.data.data.active_wallet;
                            this.$store.dispatch('setUser', user);
                            this.$emit('success-modal', 'Successfully changed');
                            setTimeout(() => {
                                this.$emit('updateAuthToken', true);
                            },2000)
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.getWalletType = !this.getWalletType;
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        openBonusLogs() {
            this.showBonusLogs = true;
            
        },
        closeBonusLogs() {
            setTimeout(() => {
                this.showBonusLogs = false;
            }, 400)
        },
        getRules() {
            this.loading = true;
            if(this.siteRuleData) {
                return;
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getLockedEarnedBonus() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_LOCKED_EARNED_BONUS, {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.lockedEarnedData = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setIsClickBetOn', false);
                this.showSuccessModalFunc('One Click Bet is inactive.')
                // this.$store.dispatch('setClickBetValue', null);
            }
        },
        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            if (inputValues)
                return inputValues.filter(item => item.isSelected)[0]?.value;
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },
        userData() {
            return this.$store.getters.stateUser;
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        onChange(event) {
            let search = event.target.value;

            this.$store.dispatch('setSearchText', search);
            //console.log("Search Text : ",this.$store?.getters?.searchText);
            if(this.$route.name === 'Home')
                this.callHomeFunction();
        },
        
        callHomeFunction() {
            const homeComponent = this.$router.getRoutes().find(route => route.name === 'Home');
            if (homeComponent) {
                const instance = homeComponent.instances.default;
                if (instance && typeof instance.searchCasinoMatches === 'function') {
                    instance.searchCasinoMatches();
                }
            }
        },
        getSearchText() {
            return this.$store?.getters?.searchText;
        },
        logoutServiceCall() {

            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message);
                            localStorage.removeItem('is_offer');
                            localStorage.removeItem('fav_sports_list');
                            // remove all cookie
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            this.$store.dispatch('resetAllState');
                            this.$router.go({ name: 'Home' });
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
       
    }
}
</script>