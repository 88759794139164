<template>
    <div class="login-model-pop-up-sec">
        <div class="modal fade" id="login-btn" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
            data-bs-backdrop="static">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button @click="refreshData()" ref="close_btn" type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                    <div class="modal-body">
                        <div class="login-body-sec">
                            <div class="login-body-lft">
                                <div class="login-header">
                                    <h2 class="modal-title" id="exampleModalLabel">{{ translatedLangData('log-in','Login Now')}}</h2>
                                </div>

                                <div class="login-option-h">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist"  v-if="siteSettings?.business_type == 2">

                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link " :class="form.type == 1 ? 'active': ''" id="phone-login-tab" data-bs-toggle="pill"
                                                data-bs-target="#phone-login" type="button" role="tab"
                                                aria-controls="phone-login" aria-selected="false" @click="()=>{ form.mobileNo=''; form.type = 1}">{{ translatedLangData('mobile-number','Mobile Number')}}</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link " :class="form.type == 2 ? 'active': ''" id="user-login-tab" data-bs-toggle="pill"
                                                data-bs-target="#user-login" type="button" role="tab"
                                                aria-controls="user-login" aria-selected="true" @click="()=>{ form.userId=''; form.type = 2}">{{ translatedLangData('user-id','User ID')}}</button>
                                        </li>
                                    </ul>
                                </div>
                                <!-- business_type == 2 -->
                                <div class="tab-content" id="pills-tabContent" v-if="siteSettings?.business_type == 2">

                                    <div class="tab-pane fade " :class="form.type == 1 ? 'show active': ''" id="phone-login" role="tabpanel"
                                        aria-labelledby="phone-login-tab" v-if="siteSettings?.business_type == 2">
                                        <div class="input-field">
                                            <div class="row g-0">
                                                <div class="col-12 col-sm-12 col-md-12">
                                                    <div class="contact-info-wrapper">
                                                        <div v-if="siteSettings" class="select-country-code">
                                                            <select  v-model="form.code" class="form-select form-control codein valid"
                                                                aria-label="Default select example" aria-invalid="false">
                                                                <option :value="allowedCountry.phonecode" v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                        :key="countryIndex" :disabled="allowedCountry.phonecode!='91'">+{{ allowedCountry.phonecode }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="input-left">
                                                            <input type="tel" :placeholder="translatedLangData('mobile-number','Mobile Number')+'*'" maxlength="10"
                                                                        v-model="form.mobileNo" @input="numberFormat('mobileNo')" @keypress="checkForNonNumericals($event)" class="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="showValidationError && form.mobileNo == ''">
                                                    <p class="wrong-message">{{ form.type == 1 ? translatedLangData('please-enter-mobile-number','Please Enter Mobile Number'): translatedLangData('please-enter-user-id','Please Enter User ID')}}</p>
                                                </div>

                                            </div>

                                            <div class="int-container-box">
                                                <input :type="form.passwordVisible ? 'text' : 'password'"
                                                :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" @input="filterSpaces($event,'password')" class="form-control" />
                                                <div class="score-hide-show">
                                                    <img loading="lazy" @click="form.passwordVisible = !form.passwordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                        v-if="form.passwordVisible" alt="img">
                                                    <img loading="lazy" v-else
                                                        @click="form.passwordVisible = !form.passwordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"
                                                        alt="img">
                                                </div>

                                                <div v-if="showValidationError && form.password == ''">
                                                    <p class="wrong-message">{{translatedLangData('please-enter-password','Please Enter Password')}}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="tab-pane fade" :class="form.type == 2 ? 'show active': ''" id="user-login" role="tabpanel"
                                        aria-labelledby="user-login-tab">
                                        <div class="input-field">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-12">
                                                    <div class="input-left">
                                                        <input type="text" v-model="form.userId" class="form-control"
                                                        @input="filterSpaces($event,'userId'),handleInputUserID"
                                                        :placeholder="translatedLangData('user-id','User ID')+'*'" />
                                                        <i class="fa-solid fa-user"></i>
                                                    </div>
                                                </div>

                                                <div v-if="showValidationError && getId() == ''">
                                                    <p class="wrong-message">{{ form.type == 1 ? translatedLangData('please-enter-mobile-number','Please Enter Mobile Number'): translatedLangData('please-enter-user-id','Please Enter User ID')}}</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="int-container-box">
                                            <input :type="form.passwordVisible ? 'text' : 'password'" :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" @input="filterSpaces($event,'password')" class="form-control"  />
                                            <div class="score-hide-show">
                                                <img loading="lazy" @click="form.passwordVisible = !form.passwordVisible"
                                                    class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                    v-if="form.passwordVisible" alt="img">
                                                <img loading="lazy" v-else
                                                    @click="form.passwordVisible = !form.passwordVisible"
                                                    class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"
                                                    alt="img">
                                            </div>

                                            <div v-if="showValidationError && form.password == ''">
                                                <p class="wrong-message">{{translatedLangData('please-enter-password','Please Enter Password')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- business_type == 1 -->
                                <div class="tab-content" id="pills-tabContent" v-else>

                                    <div class="tab-pane fade show active" id="user-login" role="tabpanel"
                                        aria-labelledby="user-login-tab">
                                        <div class="input-field">
                                            <div class="row g-2">

                                                <div class="col-12 col-sm-12 col-md-12">
                                                    <div class="input-left">
                                                        <input type="text" v-model="form.userId" class="form-control"
                                                        @input="filterSpaces($event,'userId'),handleInputUserID"
                                                        :placeholder="translatedLangData('user-id','User ID')+'*'" />
                                                        <i class="fa-solid fa-user"></i>
                                                    </div>
                                                </div>

                                                <div v-if="showValidationError && getId() == ''">
                                                    <p class="wrong-message">{{ form.type == 1 ? translatedLangData('please-enter-mobile-number','Please Enter Mobile Number'): translatedLangData('please-enter-user-id','Please Enter User ID')}}</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="int-container-box">
                                            <input :type="form.passwordVisible ? 'text' : 'password'" :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" @input="filterSpaces($event,'password')" class="form-control"  />
                                            <div class="score-hide-show">
                                                <img loading="lazy" @click="form.passwordVisible = !form.passwordVisible"
                                                    class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                    v-if="form.passwordVisible" alt="img">
                                                <img loading="lazy" v-else
                                                    @click="form.passwordVisible = !form.passwordVisible"
                                                    class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"
                                                    alt="img">
                                            </div>

                                            <div v-if="showValidationError && form.password == ''">
                                                <p class="wrong-message">{{ translatedLangData('please-enter-password','Please Enter Password') }}.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div action="">
                                    <!-- <div class="input-field">
                                        <div class="row g-2">
                                            <div class="col-4 col-sm-4 col-md-4">
                                                <select v-model="form.type" :disabled="siteSettings?.business_type==1" class="form-select form-control codein valid"
                                                    aria-label="Default select example" aria-invalid="false">
                                                    <option value="1">Mobile No.</option>
                                                    <option value="2">User ID</option>
                                                </select>
                                            </div>
                                            <div class="col-8 col-sm-8 col-md-8">
                                                <div v-if="form.type == 1" class="input-left">
                                                    <input type="tel" v-model="form.mobileNo" maxlength="10"
                                                        class="form-control" placeholder="Phone number*" />
                                                    <i class="fa-solid fa-phone"></i>
                                                </div>
                                                <div v-if="form.type == 2" class="input-left">
                                                    <input type="text" v-model="form.userId" class="form-control"
                                                        placeholder="Enter ID*" />
                                                    <i class="fa-solid fa-user"></i>
                                                </div>
                                            </div>

                                            <div v-if="showValidationError && getId()==''" class="mt-2">
                                                <p  class="wrong-message">{{form.type==1?'Please enter mobile number.':'Please enter id.'}}</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="int-container-box">
                                        <input :type="form.passwordVisible?'text':'password'" v-model="form.password" placeholder="Password"
                                            class="form-control" />
                                            <div class="score-hide-show">
                                                <img loading="lazy" @click="form.passwordVisible=!form.passwordVisible" class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                 v-if="form.passwordVisible" alt="img">
                                                <img loading="lazy" v-else @click="form.passwordVisible=!form.passwordVisible" class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"  alt="img">
                                            </div>
                                        
                                        <div  v-if="showValidationError && form.password==''" class="mt-2">
                                            <p  class="wrong-message">Please Enter Password</p>
                                        </div>
                                    </div> -->


                                    <div class="check-box-sec">
                                        <div class="box-left">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="check1" name="option1"
                                                    value="something" />                                                
                                                <label class="form-check-label" for="check1">{{ translatedLangData('remember-me','Remember Me')}}?</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="siteSettings && siteSettings.business_type == 2" class="box-right" @click="refreshData">
                                        <a href="#forget-password-btn" data-bs-toggle="modal">{{ translatedLangData('forgot-password','Forgot Password?') }}</a>
                                    </div>

                                    <div class="login-cmn-btn">
                                        <button @click="signInMethodCall()" :disabled="loading" type="button">
                                            <span v-if="!loading">{{ translatedLangData('log-in','Log In') }}</span>
                                            <div v-if="loading" class="text-center" id="Otp_loader">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">{{ translatedLangData('loading','Loading') }}...</span>
                                                </div>
                                            </div>
                                        </button>

                                        <button type="button" @click="demoUserLogin()">
                                            <span v-if="!demoLoading">{{ translatedLangData('demo-login','Login with Demo ID')}}</span>
                                            <div v-if="demoLoading" class="text-center" id="Otp_loader">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">{{ translatedLangData('loading','Loading') }}...</span>
                                                </div>
                                            </div>
                                        </button>

                                    </div>

                                    <div class="logon-option" v-if="siteSettings?.business_type == 2">

                                        <div class="or-login-with">
                                            {{ translatedLangData('or-login-with','or Login with') }}
                                        </div>

                                        <ul class="submenu social-icons mb-3">
                                            <li>
                                                <a :href="siteSettings?.social_link?.facebook_link" target="_blank" rel="noopener" class="facebook">
                                                    <img src="@/assets/images/facebook-logo.webp" alt="" />
                                                </a>
                                            </li>
                                            <!-- <li>
                                                <a :href="siteSettings?.social_link?.email" class="instagram">
                                                    <img src="@/assets/images/google-logo.webp" alt="" />
                                                </a>
                                            </li> -->
                                            <li>
                                                <a :href="siteSettings?.social_link?.instagram_link" target="_blank" rel="noopener" class="twitter">
                                                    <img src="@/assets/images/instagram-logo.webp" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a :href="siteSettings?.social_link?.telegram_link" target="_blank" class="email">
                                                    <img src="@/assets/images/telegram-logo.webp" alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>


                                    <!-- <div v-if="siteSettings?.social_link" class="Continue-with">
                                        <h3 class="whats-with">Or Continue With <span class="whats-with-now-add">
                                                Whatsapp</span></h3>
                                        <div v-if="siteSettings?.social_link && siteSettings?.social_link.whatsappno1"
                                            class="button-whatsapp">
                                            <a :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank"
                                                class="btn-whatsapp"> <i class="fa-brands fa-whatsapp"></i> Whatsapp
                                                Now </a>
                                        </div>
                                    </div> -->

                                    <div class="Continue-with" v-if="siteSettings?.social_link?.whatsappno1">
                                        <h3 class="whats-with">{{ translatedLangData('or-continue-with','Or Continue With') }} <span class="whats-with-now-add">{{ translatedLangData('whatsapp','Whatsapp') }}</span></h3>
                                                
                                        <div class="button-whatsapp">
                                            <a :href="'https://wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank" class="btn-whatsapp" > <i class="fa-brands fa-whatsapp"></i> {{ translatedLangData('whatsapp-now','Whatsapp Now') }}</a>
                                        </div>
                                    </div>

                                    <div v-if="siteSettings && siteSettings.business_type == 2" class="acc-and-join-sec">
                                        <span>{{ translatedLangData('dont-have-an-account',`Don't have an account `) }}?</span>
                                        <span @click="refreshData"><a href="#register-btn" data-bs-toggle="modal">{{ translatedLangData('register','Register') }}</a></span>
                                    </div>
                                    <div class="web-site-h">
                                        <a href="mailto:reddybook@gmail.com">{{siteSettings?.social_link?.email}}</a>
                                    </div>
                                </div>

                            </div>
                            <div class="login-body-rgt">
                                <div class="banner-side-img-sec">
                                    <div class="slider-model-pic">
                                        <div class="slider-model-pic">
                                            <img loading="lazy" src="@/assets/images/sl-lc-one-layer-two.webp" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import BannerCarousel from '@/shared/components/banner/BannerCarousel.vue';
import { Modal } from "bootstrap"

export default {
    name: "Login",
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    inject: ['translatedLangData'],
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                passwordVisible: false,
                type: 1,
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            modal: null,
            demoLoading:false
        };
    },
    mounted() {
        this.form.type = this.siteSettings?.business_type == 1 ? 2 : 1;
        if (this.$route.name == 'login' && !this.checkIsLogin()) {
            setTimeout(()=> {
                let loginModal = new Modal('#login-btn');
                loginModal.show();
            }, 500)
            this.$router.push({name: 'Home'})
        }
        else if (this.$route.name == 'login' && this.checkIsLogin()) {
            this.$router.push('/')
        }
    },
    methods: {
        handleInputUserID(event) {
            // this.form.userId = event.target.value.replace(/[^\w@#$%^&*()+\-*/]/g, ''); // Allow alphanumeric characters, underscore, and special characters like @#$%^&*()+-*/.
            const containsSpaces = /\s/.test(this.form.userId);
            if (containsSpaces) {
                this.form.userId = this.form.userId.replace(/\s/g, '');
            }
            event.target.value = this.form.userId;
        },
        filterSpaces(event, prop) {            
            const containsSpaces = /\s/.test(this.form[prop]);
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
        },
        numberFormat(prop) {
            // Convert the input to a string
            if (this.form[prop] != '') {
                let stringValue=''
                if(typeof this.form[prop]!='string'){
                    stringValue = this.form[prop].toString();
                }
                else{
                    stringValue = this.form[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                // Convert the sanitized string back to a number
                if(typeof this.form[prop]!='string'){
                    this.form[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.form[prop] = stringValue
                }                
            }
        },
        checkForNonNumericals(event) {
            // Prevent input of the letter 'e'
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
            if (event.key === '-' || event.key === '+' || event.key === '.') {
                event.preventDefault();
            }
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userId = "demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, 'demo')
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },

        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: this.siteSettings?.business_type == 1 ? 2 : 1,
            },
                this.showValidationError = false
        },
        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "") {
                    this.showValidationError = true;
                }
                else {
                    this.loginServiceCall(apiName.LOGIN_API, 'nondemo');
                }
            }
        },
        async loginServiceCall(url, type) {
            if (type == 'demo') {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }
            let id = this.getId();
            type == 'demo' ? this.demoLoading = true : this.loading = true;
            let data = {
                "cncode": this.form.code,
                "userid": id.toString(),
                "type": this.form.type,
                "password": this.form.password,
            };
            api.post(url, data).then(response => {
                type == 'demo' ? this.demoLoading = false : this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {

                            this.showSuccessModalFunc(response.data.message);
                            let userData = response.data.data;
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            //for add on all APIs
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            setHeaders()
                            this.setUserDataToStore(userData);
                            this.refreshData();
                            this.$router.go();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                type == 'demo' ? this.demoLoading = false : this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            if (this.siteSettings?.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                this.form.type = 2;
                return this.form.userId;
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        setUserDataToStore(userData) {
            let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i)
            }

            let user =
            {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.expl,
                available_credits: userData.available_credits,
                is_fresh: userData.is_fresh,
                id: userData.id,
                exp: userData.exp,
                activeWallet: userData.actw,
                cpi: userData.cpi,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
                asciiCodes: asciiCodes,
            }

            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData.chips);
            this.$store.dispatch('setEventManage', userData.lock_settings);
        }
    },
    components: {
        BannerCarousel,
    }
};
</script>

<style scoped>div.mm-dropdown ul li {
    display: flex !important;
    padding-left: 25px;
}</style>