
import App from "./App.vue";
import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';
import { createApp } from 'vue'
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/style1.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css"
import "./assets/fontawesome/css/bootstrap-icons.css"
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import "vue-gtranslate/translator.css";
import "vue-awesome-paginate/dist/style.css";
import VueAwesomePaginate from "vue-awesome-paginate";
import './registerServiceWorker'
import { Dropdown, Offcanvas } from 'bootstrap';



router.beforeEach((to, from) => {
    if(window.store?.getters.isAuthenticated && !window.store?.getters?.stateUser?.last_login_at && to.name != 'profile'){
        window.router.push({ name: 'profile', params: { type: 'CHANGE_PASSWORD' } });
    }
    // if(window.store?.getters?.stateUser?.is_fresh==1 && to?.name!='profile'){
    //     window.router.push({ name: 'profile', params: { type: 'CHANGE_PASSWORD' } });
    // }
    window.store.dispatch('setSearchText','');
})
window.addEventListener('popstate', (event) => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
});

router.beforeEach((to, from, next) => {
    if(document.querySelector('.modal.show')) {
        next(false);
    } else {
        next();
    }
    const dropdownMenu = document.querySelector('.open-menu-btn .dropdown-menu.show');
    if (dropdownMenu) {
        const headAccElement = document.querySelector('.open-menu-btn .dropdown-toggle');
        const headAcc = Dropdown.getInstance(headAccElement);
        console.log(headAcc)
        if (headAcc) {
            headAcc.hide();
        }
    }
    const offcanvasElement = document.querySelector('.offcanvas.offcanvas-start.show');
    if (offcanvasElement) {
        const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
        if (offcanvasInstance) {
            offcanvasInstance.hide();
            window.store.dispatch('setMenuShow', true)
        }
    }
});
const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies);
app.use(VueAwesomePaginate);
app.mount("#app");

// define global properties
app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";