const state = {
    siteSettings: null,
    searchText: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    isClickBetOn: false,
    favouriteGames:null,
    globalLoader:false,
    // depositAmount: 0,
    notification:0,
    languagesData:null,
    balExpDisplay:[
        'balance',
        'exposure'
    ],
    languageSelected:null,
    menuShow:true,
    sports_list:null
};
const getters = {
    siteSettings: state => state?.siteSettings,
    searchText: state => state?.searchText,
    gamesData: state => state?.gamesData,
    languagesData: state => state?.languagesData,
    languageSelected: state => state?.languageSelected,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    isClickBetOn: state => state?.isClickBetOn,
    favouriteGames:state => state?.favouriteGames,
    globalLoader:state => state?.globalLoader,
    // depositAmount:state => state?.depositAmount,
    getNotification: state => state?.notification,
    getBalExpDisplay: state => state?.balExpDisplay,
    getRecommendedMatch: state => state?.recommendedMatch,
    menuShow: state => state?.menuShow,
    sports_list: state => state?.sports_list,

};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setSearchText({ commit }, searchText) {
        commit('setSearchText', searchText);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setLanguageSelected({ commit }, languageSelected) {
        commit('setLanguageSelected', languageSelected);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setIsClickBetOn({ commit }, isClickBetOn) {
        commit('setIsClickBetOn', isClickBetOn);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    // setDepositAmount({ commit }, depositAmount) {
    //     commit('setDepositAmount', depositAmount);
    // },    
    setNotification({ commit }, notification) {
        commit('setNotification', notification);
    },
    setBalExpDisplay({ commit }, balExpDisplay) {
        commit('setBalExpDisplay', balExpDisplay);
    },
    setRecommendedMatch({ commit }, RecommendedMatch) {
        commit('setRecommendedMatch', RecommendedMatch);
    },
    setMenuShow({ commit }, menuShow) {
        commit('setMenuShow', menuShow);
    },
    setSportsList({ commit }, sports_list) {
        commit('setSportsList', sports_list);
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings
    },
    setSearchText(state, searchText) {
        state.searchText = searchText;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setLanguageSelected(state, languageSelected) {
        state.languageSelected = languageSelected;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setIsClickBetOn(state, isClickBetOn) {
        state.isClickBetOn = isClickBetOn;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    // setDepositAmount(state, depositAmount){
    //     state.depositAmount =  depositAmount
    // },    
    setNotification(state) {
        state.notification = state.notification+1;
    },
    setBalExpDisplay(state, balExpDisplay) {
        state.balExpDisplay = balExpDisplay;
    },
    setRecommendedMatch(state, RecommendedMatch) {
        state.recommendedMatch = RecommendedMatch;
    },
    setMenuShow(state, menuShow) {
        state.menuShow = menuShow;
    },
    setSportsList(state, sports_list) {
        state.sports_list = sports_list;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};